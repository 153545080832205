const ACTIONS = {
    CREATE_REQUEST: "CREATE_CHALLAN_REQUEST",
    CREATE_SUCCESS: "CREATE_CHALLAN_SUCCESS",
    CREATE_FAIL: "CREATE_CHALLAN_FAIL",

    FETCH_REQUEST: "FETCH_CHALLAN_REQUEST",
    FETCH_SUCCESS: "FETCH_CHALLAN_SUCCESS",
    FETCH_FAIL: "FETCH_CHALLAN_FAIL",

    FETCH_ALL_REQUEST: "FETCH_ALL_CHALLANS_REQUEST",
    FETCH_ALL_SUCCESS: "FETCH_ALL_CHALLANS_SUCCESS",
    FETCH_ALL_FAIL: "FETCH_ALL_CHALLANS_FAIL",

    UPDATE_REQUEST: "UPDATE_CHALLAN_REQUEST",
    UPDATE_SUCCESS: "UPDATE_CHALLAN_SUCCESS",
    UPDATE_FAIL: "UPDATE_CHALLAN_FAIL",

    DELETE_REQUEST: "DELETE_CHALLAN_REQUEST",
    DELETE_SUCCESS: "DELETE_CHALLAN_SUCCESS",
    DELETE_FAIL: "DELETE_CHALLAN_FAIL",

};

export default ACTIONS;
