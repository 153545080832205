import React from "react";
import moment from "moment";
import BaseTable from "../Utils/BaseTable";
import NumToWords from "../Utils/NumToWords";
import Line from "../Utils/Line";
import Signature from "./signature.jpg";
import {useEffect, useRef, useState, useLayoutEffect} from 'react';

const TableComponent = React.forwardRef((props, ref) => {
    let challan = props.challan;
    let {screenHeight} = props
    let refs = useRef(null);

    let headingData = [
        "S. No.",
        "Product Description",
        "Quantity",
        "Remark"
    ]

    const renderRowItem = (item, index) => {
        return (
            <tr key={index}>
                <td style={{textAlign:'center', width: "10%"}}>{index + 1}</td>
                <td style={{textAlign:'left', width: "40%"}} className={'text-justify'}>{item?.name}</td>
                <td style={{textAlign:'center', width: "10%"}}>{item?.qty + " " + (item?.uom ? item?.uom : "")}</td>
                <td style={{textAlign:'left', width: "40%"}} className={'text-justify'}>{item?.remark}</td>
            </tr>
        );
    };


    let itemsData = challan?.items ? Object.values(challan?.items) : [];
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        setHeight(refs.current.offsetHeight);
    }, []);

    return (
        <div ref={ref}>
        <div ref={refs} className="col-md-12">
            <div className="card indivisual_challan">
                <div className="col-md-12">
                    <div className="col-md-12">
                        <img style={{width: '100%', height: 120}} src={"/img/logo1.png"}/>
                        <div className="row">
                            <p style={{letterSpacing: '0.9px', paddingLeft: "10px"}}><b>Distribution Transformers, LT Panels, HT Panel, CT, PT, HT/LT Cables, Cable Jointing Kit, Earthing Material, PCC Pole, Cable Tray etc.</b></p>
                        </div>
                    </div>
                    <Line left={"10px"}/>
                    <div className="col-md-12 invoice_logo_wrapper">
                        <div className="row" style={{flexWrap: "nowrap", justifyContent: 'space-between', alignItem: 'center'}}>
                            <div> <p className="mb-1"><b>GST No:</b> 06ARFPS3941N1ZA</p></div>
                            <div> <p className="mb-1"><b>PAN No:</b> ARFPS3941N</p></div>
                            <div> <p className="mb-1"><b>MSME No:</b> UDYAM-HR-05-0022551</p></div>
                            <div> <p className="mb-1"><b>Email:</b> sales@kcs-electrical.com</p></div>
                            <div> <p className="mb-1"><b>Mob:</b> #9810959039</p></div>
                        </div>
                    </div>
                    <Line left={"10px"}/>
                    <div className="col-md-12 invoice_logo_wrapper mt-3">
                        <div className="row" style={{flexWrap: "nowrap", justifyContent: 'space-between', alignItem: 'center'}}>

                        {/*<div className="pt-2 pb-1 d-flex justify-content-sm-between">*/}
                        <h5 className="">
                            <span>{challan?.challan_number}</span>
                        </h5>

                        <h5>
                            <span>Delivery Challan</span>
                        </h5>
                        <h5>
                            <span>Date: {moment(challan?.challanDate).format("DD-MMM-YYYY")}</span>
                        </h5>
                        </div>
                    </div>
                </div>


                <div className="col-md-12">
                    <div className="row mt-5">
                        <div className="col-md-6 invoice_logo_wrapper text-left">
                            <p className={"mb-1 ml-5"}><b>To:</b></p>
                            <p className="mb-1 ml-5">{challan?.shipping_address?.name}</p>
                            <p className="mb-1 ml-5">{challan?.shipping_address?.address}</p>
                            <p className="mb-1 ml-5"><b>GSTIN: {challan?.shipping_address?.gst}</b></p>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="row mt-3">
                        <div className="col-md-6 invoice_logo_wrapper text-left">
                            <p className="mb-1 ml-5"><b>{challan?.shipping_address?.shipping_message}</b></p>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mt-0">
                    <div className="card-body p-1" style={{height: height > 1400 ? 842 : undefined}}>
                        <div className="col-md-12 invoice_logo_wrapper text-left">
                        <BaseTable
                            headingData={headingData}
                            rowData={itemsData}
                            renderRowItem={renderRowItem}
                        />
                        </div>
                    </div>
                </div>


                <div className="col md-12 mt-5 invoice_logo_wrapper">

                    <b>For KCS ELECTRICAL TRADERS & ENGINEERING</b>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    {/*<img style={{width: '10%', height: 100}} src={Signature}/>*/}
                    <p className="mb-1">Authorised signatory</p>
                </div>
            </div>

            {height < 1400 && screenHeight !== "0" && <div style={{position:"absolute",bottom: 0, top: 1470,  left:0, right: 0, textAlign:'center'}} >
                <Line />
                <p style={{letterSpacing:'0px'}}>360(old 79/4) 3rd Floor, Flat No 301,  Anamika Enclave, Behind Kalyani Hospital, Gurugram - 122001, Haryana<br/>
                    https://www.kcs-electrical.com</p>
            </div>}
            {height > 1400 && screenHeight !== "0" && <div style={{position:"absolute",bottom: 0, top: 2940,  left:0, right: 0, textAlign:'center'}} >
                <Line />
                <p style={{letterSpacing:'0px'}}>360(old 79/4) 3rd Floor, Flat No 301,  Anamika Enclave, Behind Kalyani Hospital, Gurugram - 122001, Haryana<br/>
                    https://www.kcs-electrical.com</p>
            </div>}
        </div>


        </div>
    );
})

export default TableComponent;
