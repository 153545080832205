

import React from 'react'

const Features = () => {
        return (
            <>
                <section className="featuresbg pt-5" id="services">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center pt-4">
                                <h4 className="heading_fs mb-5 pb-2">
                                    Electrical Turnkey Projects
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 col-md-2 col-sm-3 col-12 d-flex text-center">
                            <div className="features_common mb-4">
                                <div className="align-self">
                                    <div className="mb-2">
                                        <img style={{width: 70, height:70}}  src="img/coverage.png" alt="" />
                                    </div>
                                    {/*<h5>Large coverage */}
                                    {/*</h5>*/}
                                    <p>Structure and foundation design</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-12 d-flex text-center">
                            <div className="features_common mb-4">
                                <div className="align-self">
                                    <div className="mb-2">
                                        <img style={{width: 70, height:70}} src="img/operation.png" alt="" />
                                    </div>
                                    {/*<h5>Large coverage */}
                                    {/*</h5>*/}
                                    <p>PCC and MCC operation</p>
                                </div>
                            </div>
                        </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-12 d-flex text-center">
                                <div className="features_common mb-4">
                                <div className="align-self">
                                    <div className="mb-2">
                                        <img style={{width: 70, height:70}} src="img/power_distribution.png" alt="" />
                                    </div>
                                    {/*<h5>Large coverage */}
                                    {/*</h5>*/}
                                    <p>HT/LT Power distribution</p>
                                </div>
                            </div></div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-12 d-flex text-center">
                                <div className="features_common mb-4">
                                    <div className="align-self">
                                        <div className="mb-2">
                                            <img style={{width: 150, height:70}}  src="img/fire.png" alt="" />
                                        </div>
                                        {/*<h5>Large coverage */}
                                        {/*</h5>*/}
                                        <p>Fire fighting and smoke detection system</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-12 d-flex text-center">
                                <div className="features_common mb-4">
                                <div className="mb-2">
                                    <img style={{width: 70, height:70}}  src="img/circle_planning.png" alt="" />
                                </div>
                                {/*<h5>Track Your Shipments</h5>*/}
                                <p>Planning for line routing  </p>
                            </div>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-6 col-12 d-flex text-center">
                                <div className="features_common mb-4">
                                <div className="mb-2">
                                    <img style={{width: 70, height:70}} src="img/voltage.png" alt="" />
                                </div>
                                {/*<h5>Track Your Shipments</h5>*/}
                                <p> HV works of OH cum U/G up to 11 KV and above</p>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-flex text-center">
                                <div className="features_common mb-4">
                                    <div className="mb-2">
                                        <img style={{width: 100, height:70}} src="img/hv.png" alt="" />
                                    </div>
                                    {/*<h5>Automated Shipping Solution</h5>*/}
                                    <p>Construction, Cable-laying and Energisation of all types of transmission lines</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-flex text-center">
                                <div className="features_common mb-4">
                                    <div className="mb-2">
                                        <img style={{width: 100, height:70}} src="img/pressure.png" alt="" />
                                    </div>
                                    {/*<h5>Choice of Carrier </h5>*/}
                                    <p>
                                        Cable system design including analysis and calculation for cable support and pressure maintenance systems</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-flex text-center">
                                <div className="features_common mb-4">
                                    <div className="mb-2">
                                        <img style={{width: 100, height:70}} src="img/pipe.png" alt="" />
                                    </div>
                                    {/*<h5>Discounted Shipping Rates</h5>*/}
                                    <p>Duct work involving Trenching, Directional Boring and Manholes</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-flex text-center">
                                <div className="features_common mb-4">
                                    <div className="mb-2">
                                        <img style={{width: 100, height:70}} src="img/power_cable.png" alt="" />
                                    </div>
                                    {/*<h5>Warehouse Services</h5>*/}
                                    <p>High voltage power cable and accessories supply</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-flex text-center">
                                    <div className="features_common mb-4">
                                        <div className="align-self">
                                            <div className="mb-2">
                                                <img style={{width: 100, height:70}}  src="img/transmission.png" alt="" />
                                            </div>
                                            {/*<h5>Recommendation Engine</h5>*/}
                                            <p>Construction, Erection, Testing & Commissioning of all types of HV overhead transmission lines and structures of 11 KV and above
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12 d-flex text-center">
                                    <div className="features_common mb-4">
                                        <div className="align-self">
                                            <div className="mb-2">
                                                <img style={{width: 100, height:70}}  src="img/chillers.png" alt="" />
                                            </div>
                                            {/*<h5>Recommendation Engine</h5>*/}
                                            <p>Operations and maintenance of chillers and Main L.T. Panels & Distribution boards</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>
                
            </>
        )
    }

export default Features
