import React, { Component, } from "react";
import { connect } from "react-redux";
import BaseModal from "../Utils/BaseModal";
import TextInput from "../Utils/TextInput";
import SelectBox from '../Utils/SelectBox'
import moment from "moment";
import City from './city.json';
import {updateChallanRequest} from "./Duck/ChallanActions";
import Form from "react-bootstrap/Form";

class ChallanModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedShipping: null,
            selectedProduct: null,
            shipping_name: null,
            shipping_address: null,
            shipping_gst: null,
            shipping_message: null,
            challanDate: null,
            challan_number: null,
            items: {},
            _id: null
        };
    }

    componentDidMount() {
        let {challanId, challan} = this.props;
        let {_id, paid_amount, selectedStatus} = this.state;
        if(challanId && challan && challan[challanId] && !_id) {
            let currentChallan = challan[challanId]
            this.setState({
                _id: currentChallan._id,
                shipping_name: currentChallan.shipping_address.name,
                shipping_address: currentChallan.shipping_address.address,
                shipping_gst: currentChallan.shipping_address.gst,
                shipping_message: currentChallan.shipping_address.shipping_message,
                challanDate: moment(currentChallan.challanDate).format("YYYY-MM-DD"),
                challan_number: currentChallan.challan_number,
                items: currentChallan.items,
                selectedProduct: currentChallan?.items && Object.keys(currentChallan.items).map(o=>{
                    return {value: o, label: currentChallan.items[o].name}
                })
            })
        }
    }
    componentDidUpdate(preProps) {
        let {challanId, challan} = this.props;
        let {_id} = this.state;
        if(challanId && challan && challan[challanId] && !_id) {
            let currentChallan = challan[challanId]
            this.setState({
                _id: currentChallan._id,
                shipping_name: currentChallan.shipping_address.name,
                shipping_address: currentChallan.shipping_address.address,
                shipping_gst: currentChallan.shipping_address.gst,
                shipping_message: currentChallan.shipping_address.shipping_message,
                challanDate: moment(currentChallan.challanDate).format("YYYY-MM-DD"),
                challan_number: currentChallan.challan_number,
                items: currentChallan.items,
                selectedProduct: Object.keys(currentChallan.items).map(o=>{
                    return {value: o, label: currentChallan.items[o].name}
                })
            })
        }
    }

    onClickClose = () => {
        let { handelModal } = this.props;
        this.setState({
            _id: null,
            selectedShipping: null,
            selectedProduct: null,
            shipping_name: null,
            shipping_address: null,
            shipping_gst: null,
            shipping_message: null,
            challanDate: null,
            challan_number: null,
            items: {}
        });
        handelModal()
    };

    onClickSave = () => {
        this.setState({isLoading: true})
        let {
            shipping_name,
            shipping_address,
            shipping_gst,
            shipping_message,
            challanDate,
            items,
            challan_number
        }
         = this.state;
        let {updateChallan, challanId} = this.props;

        let params = {
            shipping_address: {
                name: shipping_name,
                address: shipping_address,
                gst: shipping_gst,
                shipping_message: shipping_message
            },
            challanDate: challanDate,
            items: items,
            challan_number: challan_number,
            _id: challanId
        }
        let {loggedInUser} = this.props;
        const isAdmin = loggedInUser && loggedInUser.role_id === "admin";
        if(isAdmin) {
            updateChallan(params)
        }

        setTimeout(()=>this.onClickClose(), 5000)
    };

    renderFooter = () => {
        let { isLoading  } = this.state;

        return (
            <>
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={this.onClickClose}
                >
                    Close
                </button>

                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onClickSave}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <>
                            <span className="spinner-border spinner-border-sm"></span>
                            <span className="visually-hidden"> Saving...</span>
                        </>
                    ) : "Save changes"}
                </button>
            </>
        );
    };

    handleChange = (name) => (event) => {
        let { product, client } = this.props;
        let {items, selectedProduct} = this.state;
        let value;
        if(Array.isArray(event)){
            value = event
        } else {
            value = event?.value ? event: event.target.value
        }
        this.setState({
            [name]: value,
        });
        if(name === "addShipping"){
            this.setState({selectedShipping: {}})
        }
        if(name === "selectedProduct") {
            let selectedItems = {};
            event.map((o)=>{
                selectedItems[o.value] = {};
                if(items[o.value] === undefined){
                    items[o.value] = {};
                }
                if(product[o.value] === undefined){
                    product[o.value] = {}
                }
                selectedItems[o.value].name = items[o.value].name ? items[o.value].name : product[o.value].name;
                selectedItems[o.value].qty = items[o.value].qty ? items[o.value].qty : product[o.value].qty;
                selectedItems[o.value].uom = items[o.value].uom ? items[o.value].uom : product[o.value].uom;
                selectedItems[o.value].remark = items[o.value].remark ? items[o.value].remark : product[o.value].remark;
            })
            this.setState({items: selectedItems})
        }
        if(name.includes("item")){
            console.log('+++>',name)
            let index = name.split("-")[1];
            if(name.includes("Name")) {
                items[index].name = value
            } else if (name.includes("Qty")) {
                items[index].qty = value
            } else if (name.includes("Uom")) {
                items[index].uom = value
            } else if (name.includes("Remark")) {
                items[index].remark = value
            }

            this.setState({items})
        }

        if(name === "selectedShipping") {
            let shipping_name = client[event.value].name
            let shipping_address = client[event.value].address
            let shipping_gst = client[event.value].gstin
            this.setState({shipping_name, shipping_address, shipping_gst})
        }
    };

    render() {
        let {
            show,
            client,
            product,
            challanId
        } = this.props;
        let {
            selectedProduct,
            challanDate,
            items,
            shipping_name,
            shipping_address,
            shipping_gst,
            challan_number,
            shipping_message
        } = this.state;
        let title = challan_number + " (" +(moment(challanDate).format("DD-MMM-YYYY")) + ")";
        console.log(selectedProduct, items)
        return (
            <BaseModal
                show={show}
                size={"xl"}
                key={challanId}
                // dialogClassName="modal-90w"
                handleClose={this.onClickClose}
                title={title}
                footerComponent={this.renderFooter}
            >
                <form key={challanId}>

                    <div className={"row"}>
                        <div className="col-xl-2 col-2 col-md-2">
                            <TextInput
                                labelClassName={"text-capitalize"}
                                labelText={"Challan Number"}
                                value={challan_number}
                                onChange={this.handleChange("challan_number")}

                            />
                        </div>
                        <div className="col-xl-2 col-2 col-md-2">
                            <label className={"text-capitalize"}>Challan Date</label>
                            <Form.Control value={challanDate} onChange={this.handleChange("challanDate")} type="date" name='date_of_birth' className={"text-capitalize"} />
                        </div>
                    </div>

                    <p>Shipping To: </p>
                    <div className={'row'}>
                            <div className={"col-xl-4 col-4 col-md-4"}>
                            <TextInput
                                labelClassName={"text-capitalize"}
                                labelText={"Name"}
                                value={shipping_name}
                                onChange={this.handleChange("shipping_name")}
                            />
                            </div>
                            <div className={"col-xl-4 col-4 col-md-4"}>
                            <TextInput
                                labelClassName={"text-capitalize"}
                                labelText={"Address"}
                                value={shipping_address}
                                onChange={this.handleChange("shipping_address")}
                            />
                            </div>
                            <div className={"col-xl-4 col-4 col-md-4"}>
                                <TextInput
                                labelClassName={"text-capitalize"}
                                labelText={"GST"}
                                value={shipping_gst}
                                onChange={this.handleChange("shipping_gst")}
                            />
                            </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-xl-12 col-12 col-md-12">
                            <TextInput
                                labelClassName={"text-capitalize"}
                                labelText={"Message"}
                                value={shipping_message}
                                onChange={this.handleChange("shipping_message")}

                            />
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className="col-xl-11 col-11 col-md-11">
                            <SelectBox onChange={this.handleChange("selectedProduct")} multiple={true} value={selectedProduct} labelText={"Product"} options={product && Object.values(product).length > 0 && Object.values(product).map(o=> {
                                return {
                                    value: o._id,
                                    label: o.name
                                }
                            })}/>

                        </div>

                    </div>
                    {selectedProduct && Array.isArray(selectedProduct) && selectedProduct.map((o, i)=>{
                        return (
                            <div className={'row'} key={o.value}>
                                <div className={"col-xl-1 col-1 col-md-1 text-center"}>
                                    <TextInput
                                        disabled={true}
                                        labelClassName={"text-capitalize"}
                                        labelText={i === 0 && "Sl.No."}
                                        value={i+1}
                                    />
                                </div>
                                <div className={"col-xl-5 col-5 col-md-5 text-center"}>
                                    <TextInput
                                        labelClassName={"text-capitalize"}
                                        labelText={i === 0 && "Description"}

                                        value={items[o.value]?.name}
                                        onChange={this.handleChange(`itemName-${o.value}`)}
                                    />
                                </div>

                                <div className={"col-xl-1 col-1 col-md-1 text-center"}>
                                    <TextInput
                                        labelText={ i === 0 && "Qty"}
                                        style={{textAlign:'center'}}
                                        value={items[o.value].qty}
                                        onChange={this.handleChange(`itemQty-${o.value}`)}
                                    />
                                </div>
                                <div className={"col-xl-1 col-1 col-md-1 text-center"}>
                                    <TextInput
                                        labelText={ i === 0 && "Uom"}
                                        style={{textAlign:'center'}}
                                        value={items[o.value].uom}
                                        onChange={this.handleChange(`itemUom-${o.value}`)}
                                    />
                                </div>
                                <div className={"col-xl-4 col-4 col-md-4 text-center"}>
                                    <TextInput
                                        style={{textAlign:'center'}}
                                        labelText={ i === 0 && "Remark"}
                                        value={items[o.value].remark}
                                        onChange={this.handleChange(`itemRemark-${o.value}`)}
                                    />
                                </div>
                            </div>
                        )
                    })}


                </form>
            </BaseModal>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        client: state?.client?.clients,
        product: state?.product?.products,
        challan: state?.challan?.challan,
        loading: state?.client?.loading,
        error: state?.client?.error,
        loggedInUser: state?.loggedInUser?.data?.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateChallan: (params) => dispatch(updateChallanRequest(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChallanModal);
