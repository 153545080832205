const ACTIONS = {
    CREATE_REQUEST: "CREATE_CLIENT_REQUEST",
    CREATE_SUCCESS: "CREATE_CLIENT_SUCCESS",
    CREATE_FAIL: "CREATE_CLIENT_FAIL",

    FETCH_REQUEST: "FETCH_CLIENT_REQUEST",
    FETCH_SUCCESS: "FETCH_CLIENT_SUCCESS",
    FETCH_FAIL: "FETCH_CLIENT_FAIL",

    FETCH_ALL_REQUEST: "FETCH_ALL_CLIENTS_REQUEST",
    FETCH_ALL_SUCCESS: "FETCH_ALL_CLIENTS_SUCCESS",
    FETCH_ALL_FAIL: "FETCH_ALL_CLIENTS_FAIL",

    UPDATE_REQUEST: "UPDATE_CLIENT_REQUEST",
    UPDATE_SUCCESS: "UPDATE_CLIENT_SUCCESS",
    UPDATE_FAIL: "UPDATE_CLIENT_FAIL",

    DELETE_REQUEST: "DELETE_CLIENT_REQUEST",
    DELETE_SUCCESS: "DELETE_CLIENT_SUCCESS",
    DELETE_FAIL: "DELETE_CLIENT_FAIL",

};

export default ACTIONS;
