const ACTIONS = {
    CREATE_REQUEST: "CREATE_GALLERY_REQUEST",
    CREATE_SUCCESS: "CREATE_GALLERY_SUCCESS",
    CREATE_FAIL: "CREATE_GALLERY_FAIL",

    FETCH_REQUEST: "FETCH_GALLERY_REQUEST",
    FETCH_SUCCESS: "FETCH_GALLERY_SUCCESS",
    FETCH_FAIL: "FETCH_GALLERY_FAIL",

    FETCH_ALL_REQUEST: "FETCH_ALL_GALLERYS_REQUEST",
    FETCH_ALL_SUCCESS: "FETCH_ALL_GALLERYS_SUCCESS",
    FETCH_ALL_FAIL: "FETCH_ALL_GALLERYS_FAIL",

    UPDATE_REQUEST: "UPDATE_GALLERY_REQUEST",
    UPDATE_SUCCESS: "UPDATE_GALLERY_SUCCESS",
    UPDATE_FAIL: "UPDATE_GALLERY_FAIL",

    DELETE_REQUEST: "DELETE_GALLERY_REQUEST",
    DELETE_SUCCESS: "DELETE_GALLERY_SUCCESS",
    DELETE_FAIL: "DELETE_GALLERY_FAIL",

};

export default ACTIONS;
