const ACTIONS = {
    FETCH_REQUEST: "FETCH_ABOUT_REQUEST",
    FETCH_SUCCESS: "FETCH_ABOUT_SUCCESS",
    FETCH_FAIL: "FETCH_ABOUT_FAIL",

    UPDATE_REQUEST: "UPDATE_ABOUT_REQUEST",
    UPDATE_SUCCESS: "UPDATE_ABOUT_SUCCESS",
    UPDATE_FAIL: "UPDATE_ABOUT_FAIL",

};

export default ACTIONS;
